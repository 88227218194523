<template>
  <el-dialog
    :visible.sync="peopleProfileShow"
    width="22%"
    :before-close="handleClose"
    :show-close="true"
    top="10%"
    append-to-body
    :profileInfo="profileInfo"
    v-move-outside
  >
    <div class="dialog_body">
      <div class="size-icon-message">
        <!-- <i class="el-icon-message"></i> -->
        <img :src="profileInfo.url" alt="" />
        <!-- <el-avatar shape="circle" :size="120" :src="imageUrl"> </el-avatar> -->
      </div>
      <div class="verify_body">
        <p class="p1">{{ profileInfo.name }}</p>
        <p class="p2">
          {{ profileInfo.title }} at {{ profileInfo.companyName }}
        </p>
        <p class="p3">6:05 am UTC</p>
        <!-- <p class="p4_1">
          Please click on the link in that email to complete your signup
        </p> -->
        <!-- <p class="p4_2">
          if you don't see it, you may need to check your spam folder
        </p> -->
        <p class="p5">Ping {{ profileInfo.name }}</p>
        <p class="p6" @click="linkToProfile()">Profile</p>
        <p class="p7">View Company</p>
        <!-- <p v-if="from == 'order'" class="p7">Remove from Order</p> -->
        <!-- <el-button>Ping Wyn</el-button> -->
        <!-- <div class="verify_body_bottom">
          <p class="p6_help">Need help?</p>
          <p class="p6_contact">Contact us</p>
        </div> -->
      </div>
    </div>
    <!-- <addPeople
      :addPeopleShow.sync="addPeopleShow"
      @handleClose="addPeopleClose"
    /> -->
  </el-dialog>
</template>

<script>
//import addPeople from "@/components/addPeople/addPeople";
export default {
  name: 'peopleProfile',
  components: {},
  data() {
    return {
      imageUrl: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/boat.png',
      form: {},
    };
  },
  props: ['peopleProfileShow', 'profileInfo', 'from'],
  methods: {
    linkTo(path) {
      this.$router.push({
        path,
      });
    },
    handleClose() {
      this.$emit('handleClose');
    },
    // addPeopleClose() {
    //   this.addPeopleShow = false;
    // },
    linkToProfile() {
      this.linkTo(
        '/profile?name=' +
          this.profileInfo.name +
          '&title=' +
          this.profileInfo.title +
          '&email=' +
          this.profileInfo.email +
          '&phone=' +
          this.profileInfo.phone +
          '&country=' +
          this.profileInfo.country +
          '&timeZone=' +
          this.profileInfo.timeZoneEnum +
          '&companyName=' +
          this.profileInfo.companyName +
          '&profileUrl=' +
          this.profileInfo.url +
          '&manageCompany=' +
          this.profileInfo.manageCompany
      );
      this.handleClose();
    },

    // handleAvatarSuccess(res, file) {
    //   this.imageUrl = URL.createObjectURL(file.raw);
    // },
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === "image/jpeg";
    //   const isLt2M = file.size / 1024 / 1024 < 2;

    //   if (!isJPG) {
    //     this.$message.error("上传头像图片只能是 JPG 格式!");
    //   }
    //   if (!isLt2M) {
    //     this.$message.error("上传头像图片大小不能超过 2MB!");
    //   }
    //   return isJPG && isLt2M;
    // },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  border-radius: 8px 8px 0 0;
  background-color: rgb(204, 204, 202);
  font-weight: 700;
  // padding: 5px 0 5px 5px;
}

/deep/ .el-button {
  // position: relative;
  font-size: 18px;
  background-color: #2e9993;
  color: rgb(255, 250, 250);
  width: 60%;
}

.size-icon-message {
  font-size: 55px;
  img {
    width: 120px;
    height: 120px;
    display: block;
    border-radius: 50%;
  }
}

.dialog_body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 52vh;
  overflow: overlay;
}
.verify_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .p1 {
    font-size: 20px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    margin: 15px 0 5px 0;
  }

  .p2 {
    font-size: 18px;
    font-weight: 500;
    color: rgb(250, 248, 248);
  }

  .p3 {
    font-size: 19px;
    font-weight: 700;
    color: rgb(250, 249, 249);
    margin: 0 0 23px 0;
    cursor: pointer;
  }

  .p4_1 {
    font-size: 16px;
    font-weight: 500;
    color: rgb(250, 248, 248);
    margin: 0 25px 0px 25px;
  }
  .p4_2 {
    font-size: 16px;
    font-weight: 500;
    color: rgb(250, 248, 248);
    margin: 0 20px 23px 20px;
  }

  .p5 {
    width: 280px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    font-weight: 500;
    color: rgb(250, 248, 248);
    text-align: center;
    // padding: 5px 75px 5px 75px;
    margin: 20px 0 0 0;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);

    &:hover {
      background: rgba(250, 248, 248, 0.3);
      cursor: pointer;
    }
  }
  .verify_body_bottom {
    font-size: 16px;
    font-weight: 500;
    color: rgb(250, 248, 248);
    margin: 23px 0 0 0;
  }
  .p6_help {
    display: inline;
  }

  .p6 {
    width: 280px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    font-weight: 500;
    color: rgb(250, 248, 248);
    text-align: center;
    // padding: 5px 75px 5px 75px;
    margin: 10px 0 5px 0;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);

    &:hover {
      background: rgba(250, 248, 248, 0.3);
      cursor: pointer;
    }
  }
  .p7 {
    width: 280px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    font-weight: 500;
    color: rgb(250, 248, 248);
    text-align: center;
    // padding: 5px 75px 5px 75px;
    margin: 5px 0 5px 0;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);

    &:hover {
      background: rgba(250, 248, 248, 0.3);
      cursor: pointer;
    }
  }
}
</style>
