<template>
  <div class="container">
    <img class="customerBackground" src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/loginFont/%E4%B8%BB%E9%A1%B5%E8%83%8C%E6%99%AF.jpg" alt="">
    <div class="routerBox" style="position: relative;z-index: 2;margin-top: 12px;">
      <div class="customerTitle">
        <div class="navBack" @click="$router.go(-1)">
          <i class="el-icon-arrow-left"></i>
          {{ $t('el.pageHeader.title') }}
        </div>
        <div class="right" @click="goDashboard">
          <!-- <i class="iconfont icon-shujukanban1"></i> -->
          <EcIcon type="shujukanban1" />
          {{ $t('home.MyDashboard') }}
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="customerInfoMain">
        <div class="companyWrapper">
          <img :src="customerInfo.companyLogoUrl" alt="logo" class="companyLogo" />
          <div class="companyContainer">
            <div class="companyContainerItem">
              <p class="companyType">
                {{ $t("customerInfo.Name") }}
              </p>
              <p class="companyName">
                {{ customerInfo.aliasName || customerInfo.name }}
                <router-link
                  tag="a"
                  :to="`/companyProfile?companyId=${customerInfo.companyId}&type=see`"
                  class="company-more-details"
                >
                  {{ $t("customerInfo.details") }}
                </router-link>
              </p>
            </div>
            <div class="companyContainerItem">
              <p class="companyType">
                {{ $t("customerInfo.Contact") }}
              </p>
              <p class="companyName">{{ customerInfo.contact }}</p>
            </div>
            <div class="companyContainerItem">
              <p class="companyType">
                {{ $t("customerInfo.VAT") }}
              </p>
              <p class="companyName">{{ customerInfo.vat }}</p>
            </div>
            <div class="companyContainerItem">
              <p class="companyType">
                {{ $t("customerInfo.Email") }}
              </p>
              <p class="companyName">{{ customerInfo.email }}</p>
            </div>
            <div class="companyContainerItem">
              <p class="companyType">
                {{ $t("customerInfo.Address") }}
              </p>
              <p class="companyName">
                {{ customerInfo.address }}
              </p>
            </div>
            <div class="companyContainerItem" style="margin-top: 10px;align-items: center;">
              <p class="companyType">
                {{ $t("customerInfo.Member") }}
              </p>
              <div class="companyMoreWrapper">
                <template v-for="(item, index) in memberListComputed">
                  <member 
                    :key="index"
                    :info="item" 
                    :size="36" 
                    :placement="'bottom'"
                    v-if="index < 3"
                  ></member>
                </template>
                <div class="moreImage" @click="visible = !visible">
                  <el-image :src="customerInfo.memberList && customerInfo.memberList[customerInfo.memberList.length - 1] && customerInfo.memberList[customerInfo.memberList.length - 1].avatarFileUrl" />
                  <div class="mask">+{{ (customerInfo.memberList && customerInfo.memberList.length) - (memberListComputed && memberListComputed.length) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-card style="height: 310px">
          <router-link
            tag="div"
            :to="`/customer/schedule?companyID=${$route.query.companyId}`"
            class="card-header"
          >
            <h3>
              {{ $t("customerInfo.Schedule") }}
            </h3>
            <i class="el-icon-arrow-right"></i>
          </router-link>
          <el-calendar class="customerCalendar" v-model="calendarValue">
            <template slot="dateCell" slot-scope="{data}">
              <p :class="data.isSelected ? 'is-selected' : ''">
                {{ data.day.split('-').slice(2).join('-') }}
              </p>
            </template>
          </el-calendar>
        </el-card>
        <!-- <el-card class="customerMessageCard" style="height: 310px;" v-if="customerInfo.companyType != 2"> -->
          <!-- <div @click="openNoticeDrawer" class="card-header" style="margin: 0 12px 12px 12px;"> -->
            <!-- <h3> -->
              <!-- {{ $t("customerInfo.Message Board") }} -->
            <!-- </h3> -->
            <!-- <i class="el-icon-arrow-right"></i> -->
          <!-- </div> -->
          <!-- <div class="customerMessageScroll"> -->
            <!-- <div -->
              <!-- class="customerMessageWrapper" -->
              <!-- v-for="(item, index) in sendMessages" -->
              <!-- :key="index" -->
              <!-- @click="openNoticeDrawer(item.noticeId)" -->
            <!-- > -->
              <!-- <member :info="item.createPerson" :size="48"></member> -->
              <!-- <div class="messageContainer"> -->
                <!-- <h3 v-showTootip>{{ item.title }}</h3> -->
                <!-- <p>{{ item.createPerson.nickName }}</p> -->
              <!-- </div> -->
            <!-- </div> -->
            <!-- <el-empty -->
              <!-- v-if="sendMessages && !sendMessages.length" -->
              <!-- :image="$emptyImage" -->
              <!-- :image-size="90" -->
              <!-- :description="$t('overallSituation.noData')" -->
            <!-- /> -->
          <!-- </div> -->
        <!-- </el-card> -->
        <el-card class="customerMessageCard" style="height: 310px;">
          <div class="card-header" style="margin: 0 12px 0 12px;">
            <div class="translateButton">
              <el-tabs
                class="special_tabs"
                v-model="shopServerType"
                size="small">
                <el-tab-pane :label="customerInfo.goodsFlag == 1 ? $t(`noticeServer.Server`) : $t(`noticeServer.Shop`)" :name="'0'" />
                <el-tab-pane :label="$t('noticeServer.Case')" :name="'1'" />
                <el-tab-pane :label="$t('noticeServer.File')" :name="'2'" />
              </el-tabs>
            </div>
            <i class="el-icon-arrow-right" @click="openShopServerDrawer"></i>
          </div>
          <div class="shopServerBox">
            <ServerPage 
              ref="ServerPage" 
              v-if="shopServerType == '0' && customerInfo.goodsFlag == 1" 
              :params="shopServerParams" 
              :rowSpan="24"
            />
            <ShopPage 
              ref="ServerPage" 
              v-else-if="shopServerType == '0'" 
              :params="shopServerParams" 
              :rowSpan="12"
            />
            <CasePage 
              ref="CasePage" 
              v-if="shopServerType == '1'" 
              :params="shopServerParams"
              :rowSpan="12"
            />
            <FilePage 
              ref="FilePage" 
              v-if="shopServerType == '2'" 
              :params="shopServerParams" 
              :rowSpan="12"
            />
          </div>
        </el-card>
        <el-card class="customerMessageCard" style="height: 310px">
          <div
            class="card-header"
            style="margin: 0 12px 12px 12px;"
          >
            <h3>
              {{ $t("customerInfo.Connection") }}{{ cooperateTitle }}
            </h3>
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="customerConnectionWrapper">
            <template v-if="companyConnectionArray.length">
              <swiper
                ref="mySwiper"
                :options="{
                  spaceBetween: 10,
                  slidesPerView: 2,
                  slidesPerColumn: 2,
                  slidesPerColumnFill: 'row',
                  autoplay: {
                    delay: 15000,
                    disableOnInteraction: false,
                  }
                }"
                @slideChange="slideConnectionChange"
              >
                <swiper-slide
                  v-for="(item, index) in companyConnectionArray"
                  :key="index"
                >
                  <router-link
                    :to="`/customer?companyId=${item.companyId}`"
                  >
                    <el-image
                      :src="item.companyLogoUrl"
                      fit="contain"
                    >
                      <i class="el-icon-picture-outline" slot="error"></i>
                    </el-image>
                  </router-link>
                </swiper-slide>
              </swiper>
              <div class="swiperPagination">
                <div 
                  class="pageItem" 
                  v-for="(item, index) in connectionSwiperPageTotal" 
                  :key="index"
                  :class="item === connectionSwiperPageActive ? 'active' : ''"
                  @click="swiperPageClick(item)"
                ></div>
              </div>
            </template>
            <el-empty
              v-else
              :image="$emptyImage"
              :image-size="90"
              :description="$t('overallSituation.noData')"
            />
          </div>
        </el-card>
      </div>
      <el-card>
        <pageTable 
          :data="orderList"
          @sort-change="sortChange"
          ref="table"
          v-loading="loading_table"
          :searchParams.sync="form"
          @searchEvent="searchGetOrderList"
          @clearEvent="clearFilter"
          @updateTable="getOrderList">
          <template #searchForm>
            <el-input
              v-model="form.pi"
              :placeholder="$isOrderPITitle()"
              style="width: 200px;"
              size="small"
              auto-complete="off"
              clearable
              @keyup.enter.native="handleFilter"
            />
            <el-input
              v-model="form.ci"
              :placeholder="$t('customerInfo.Input CI')"
              v-if="$store.state.user.orderAlias == 0"
              style="width: 200px;"
              size="small"
              auto-complete="off"
              clearable
              @keyup.enter.native="handleFilter"
            />
            <el-autocomplete
              v-model="form.uploadPerson"
              size="small"
              :placeholder="$t('InformationCenter.Createby')"
              style="width: 200px;"
              class="filter-item"
              clearable
              :fetch-suggestions="getListCompanyUserArraySelect"
              @select="uploadPersonSelect"
              suffix-icon="el-icon-search"
            >
              <template slot-scope="{ item }">
                <div style="display: flex; align-items: center; column-gap: 12px">
                  <el-avatar size="small" :src="item.avatarFileUrl"></el-avatar>
                  <span
                    style="
                      width: 145px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    >{{ item.nickName }}</span
                  >
                </div>
              </template>
            </el-autocomplete>
            <el-date-picker
              size="small"
              v-model="dateRange"
              type="daterange"
              style="width: 230px;"
              :start-placeholder="$t('customerInfo.Start from')"
              :end-placeholder="$t('customerInfo.End to')"
            >
            </el-date-picker>
          </template>
          <template #searchAction>
            <el-button
              v-if="
                customerInfo && 
                customerInfo.companyType == '0' &&
                user.companyCurrentType == '1'
              "
              v-permission="'create:order:btn'"
              size="small"
              type="primary"
              @click="createOrder()"
              icon="el-icon-plus"
            >
              {{ $tc("order.New Order", $isOrderTitle()) }}
            </el-button>
            <el-button
              size="small"
              icon="el-icon-download"
              @click="handleDownload"
            >
              {{ $t('customerInfo.Export') }}
            </el-button>
          </template>
          <template #tableColumn>
            <el-table-column type="index" label="No." width="70">
            </el-table-column>
            <el-table-column
              ref="popover_order"
              type="index"
              label="Follow"
              width="70"
              :filters="[
                { text: $t('home.Follow'), value: '1' },
                { text: $t('home.Nofollow'), value: '0' },
              ]"
              :filter-multiple="false"
              :filtered-value="filteredFollow"
            >
              <!-- :filter-method="filterFollow" -->
              <template slot="header">
                <i
                  class="iconfont icon-yishoucang"
                  style="font-size: 16px; vertical-align: middle;color: #DF8F31;"
                ></i>
              </template>
              <template slot-scope="scope">
                <div class="star">
                  <i
                    class="iconfont"
                    :class="
                      scope.row.favorite ? 'icon-yishoucang' : 'icon-shoucang'
                    "
                    @click.stop="
                      !scope.row.favorite ? followOrder(scope.row, scope.$index) : unFollowOrder(scope.row, scope.$index)
                    "
                  ></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="receiptCountry"
              :label="$t('order.Please select country')"
              column-key="receiptCountry"
              min-width="100"
            >
              <template slot-scope="scope">
                {{ $isFindData(country, scope.row.country, 'value', 'key') }}
              </template>
            </el-table-column>
            <el-table-column sortable prop="pi" :label="$isOrderPITitle()" min-width="150">
              <template slot-scope="scope">
                <div
                  @click="!scope.row.ci ? linkToOrder(scope.row) : ''"
                  :class="!scope.row.ci ? 'aLink' : ''"
                >
                  {{ scope.row.pi }}
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="$store.state.user.orderAlias == 0" sortable prop="ci" label="CI" min-width="130">
              <template slot-scope="scope">
                <div
                  @click="linkToOrder(scope.row)"
                  class="aLink"
                >{{ scope.row.ci }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('InformationCenter.Createby')" min-width="160">
              <template slot-scope="scope">
                <div class="customerName">
                  <member :info="scope.row.openUserDTO" :size="28"></member>
                  <span>{{ scope.row.openUserDTO && scope.row.openUserDTO.nickName }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              sortable
              :label="$t('order.Create Time')"
              column-key="createTime"
              min-width="190"
            >
            </el-table-column>
            <el-table-column
              prop="currency"
              column-key="currency"
              :filters="currencys.map(item => ({
                text: item.key,
                value: item.value
              }))"
              :filter-multiple="false"
              :filtered-value="filterCurrency"
              :label="$t('home.Currency')"
              min-width="120"
            >
            </el-table-column>
            <el-table-column
              prop="amount"
              sortable
              :label="$t('order.Amount')"
              min-width="100"
              column-key="amount"
            >
              <template slot-scope="scope">
                <a>{{ count(Number(scope.row.amount)) }}</a>
              </template>
            </el-table-column>
            <el-table-column
              v-if="isDefaultNode"
              :filters="orderProgress.map(item => ({
                text: item.key,
                value: item.value
              }))"
              :filter-multiple="false"
              :filtered-value="filterStatus"
              :label="$t('order.Status')"
              min-width="250"
              class-name="orderStateColumn"
            >
              <template slot-scope="scope">
                <div class="orderStateProgress">
                  <el-tooltip
                    effect="light"
                    placement="top"
                  >
                    <div class="progressContent" slot="content">
                      <div 
                        class="item" 
                        v-for="(item, index) in scope.row.progressList.filter((item, index) => index <= scope.row.progressList.findIndex(row => row.progress == scope.row.progress))" 
                        :key="index">
                        <div class="label">
                          <i class="el-icon-check"></i>
                          {{ computedNodeList(item.progress).name }}
                        </div>
                        <div class="time">{{ progressTime(item, scope.row) }}</div>
                      </div>
                    </div>
                    <div
                      class="stateIconBox" 
                      :style="{
                        left: `calc(${scope.row.onProgress}% - 10px)`,
                        background: orderStatusColors[scope.row.progressList.findIndex(item => item.progress == scope.row.progress)]
                      }">
                      <i :class="`state_icon iconfont ${scope.row.icon}`"></i>
                    </div>
                  </el-tooltip>
                  <el-progress :percentage="scope.row.onProgress" :color="orderStatusColors[scope.row.progressList.findIndex(item => item.progress == scope.row.progress)]" :stroke-width="8" :show-text="false"></el-progress>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :label="$t('order.Status')"
              min-width="250"
              class-name="orderStateColumn"
            >
              <template slot-scope="scope">
                <div class="orderStateProgress">
                  <el-tooltip
                    effect="light"
                    placement="top"
                  >
                    <div class="progressContent" slot="content">
                      <div 
                        class="item" 
                        v-for="(item, index) in scope.row.progressList.filter((item, index) => index <= scope.row.progressList.findIndex(row => row.progress == scope.row.progress))" 
                        :key="index">
                        <div class="label">
                          <i class="el-icon-check"></i>
                          {{ computedNodeList(item.progress).name }}
                        </div>
                        <div class="time">{{ progressTime(item, scope.row) }}</div>
                      </div>
                    </div>
                    <div
                      class="stateIconBox" 
                      :style="{
                        left: `calc(${scope.row.onProgress}% - 10px)`,
                        background: orderStatusColors[scope.row.progressList.findIndex(item => item.progress == scope.row.progress)]
                      }">
                      <i :class="`state_icon iconfont ${scope.row.icon}`"></i>
                    </div>
                  </el-tooltip>
                  <el-progress :percentage="scope.row.onProgress" :color="orderStatusColors[scope.row.progressList.findIndex(item => item.progress == scope.row.progress)]" :stroke-width="8" :show-text="false"></el-progress>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('customerInfo.Action')"
              min-width="90"
              fixed="right"
            >
              <template slot-scope="scope">
                <div class="action">
                  <div
                    class="btn" 
                    v-if="scope.row.createPerson == businessUserId && scope.row.progress != '6'"
                    @click="editOrder(scope.row)">
                    <i class="el-icon-edit"></i>
                  </div>
                  <el-dropdown
                    trigger="click" 
                    placement="bottom-start"
                    @visible-change="visibleDropdownChange">
                    <div
                      class="btn" 
                      :class="tableSelectedShow === scope.$index ? 'active' : ''"
                      @click="tableSelectedShow = scope.$index">
                      <i class="iconfont icon-Ellipsis"></i>
                    </div>
                    <el-dropdown-menu class="ec-popper" slot="dropdown">
                      <el-dropdown-item
                        class="popperMenuItem"
                        icon="iconfont icon-xianshi"
                        @click.native="orderId = scope.row.orderId"
                      >{{ $t('order.skuPreview') }}</el-dropdown-item>
                      <el-dropdown-item
                        class="popperMenuItem"
                        icon="iconfont icon-shanchu"
                        v-if="scope.row.createPerson == businessUserId"
                        @click.native="deleteOrder(scope.row)"
                      >{{ $t('home.Delete') }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </template>
        </pageTable>
      </el-card>
    </div>
    <createOrder
      ref="createOrderDialog"
      :customerList="customerList"
      :editContent="editContent"
      :customerInfo="customerInfo"
      @afterCreateOrder="afterCreateOrder"
      :action="action"
    />
    <skuView :orderId="orderId" @close="orderId = ''"></skuView>
    <MemberList
      :manageCompany="true"
      :visible="visible"
      @close="visible = !visible"
      @updateFun="companyUsers = $event"
    ></MemberList>
    <deleteOrder
      :deleteOrderShow.sync="deleteOrderShow"
      @handleClose="deleteOrderShow = false"
      :deleteInfo="deleteInfo"
      @afterDeleteOrder="afterDeleteOrder"
    />
    <noticeDrawer ref="noticeDrawer" />
    <shopServerManage ref="shopServerManage" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import store from '@/store';
import { mapGetters } from 'vuex';
import { formatNum } from '@/utils/utils';
import createOrder from '@/components/createOrder/createOrder';
import peopleProfile from '@/components/peopleProfile/peopleProfile';
import Steps from '@/components/orderProgress/orderProgress';
import noticeDrawer from "@/components/noticeDrawer";
import deleteOrder from "@/components/deleteOrder/deleteOrder";
import shopServerManage from "@/components/shopServerManage";
import ShopPage from '@/components/shopServerManage/components/shop.vue';
import ServerPage from '@/components/shopServerManage/components/server.vue';
import CasePage from '@/components/shopServerManage/components/case.vue';
import FilePage from '@/components/shopServerManage/components/fileList.vue';
import { getCompanyEmployeeList } from '@/api/customer';
import { getOrderList, follow, unfollow } from '@/api/order';
import { getUserCustomList, getCooperateCustomer, listCompanyUsers, getIsMyCustomer } from '@/api/customer';
import { getSendMessageList } from "@/api/message";

export default {
  name: 'Customer',
  components: {
    createOrder,
    peopleProfile,
    Steps,
    noticeDrawer,
    deleteOrder,
    shopServerManage,
    ShopPage,
    ServerPage,
    CasePage,
    FilePage
  },
  inject: ['reload'],
  // 监听表格滚动
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector('.el-table__body-wrapper');
        selectWrap.addEventListener('scroll', function () {
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;

          if (scrollDistance <= 0.85) {
            binding.value(); //执行在使用时绑定的函数，在这里即loadMorePerson方法
          }
        });
      },
    },
  },
  data() {
    return {
      visible: false,
      shopServerParams: {
        keyword: "",
        sortType: "desc",
        companyId: ""
      },
      calendarValue: new Date(),
      shopServerType: "0",
      tableSelectedShow: null,
      companyUsers: [],
      customerList:[],
      sendMessages: [],
      connectionSwiperPageTotal: [],
      connectionSwiperPageActive: 0,
      companyConnectionArray: [],
      orderId: '',
      user: store.state.user,
      searchVal: '',
      deleteInfo: null,
      deleteOrderShow: false,
      peopleProfileShow: false,
      loading_table: false,
      page: 'customer',
      customerInfo: {
        name: '',
        vat: '',
        companyId: '',
        logoId: '',
        contact: '',
        email: '',
        country: '',
        url: '',
      },
      sortTypeFollow: '',
      filteredFollow: [],
      filterCurrency: [],
      filterStatus: [],
      filterCompany: [],
      dateRange: [],
      form: {
        companyId: '',
        uploadPerson: "",
        ci: '',
        pi: '',
        sortType: '',
        sortField: '',
        currency: '',
        orderProgress: '',
        follow: '',
        currentPage: 1,
        pageSize: 10,
        totalElement: 0
      },
      orderList: [],
      statusEnum: [
        'PRODUCING',
        'DELIVERY_LOCAL',
        'SEND_PORT',
        'DELIVERY_TRANS',
        'RECEIVE_PORT',
        'DELIVERY_DEST',
        'DESTINATION',
      ],
      action: '',
      data_order: {
        currentPage: 1,
        pageSize: 10,
      },
      pagTotal: 1,
      editContent: '',
      isMyCustomer: true
    };
  },
  computed: {
    ...mapGetters([
      'timezone', 
      'country', 
      'companyCurrentType', 
      'orderStatusColors', 
      'orderProgress', 
      'currencys',
      'businessUserId',
      "newOrderUpdate",
      "isDefaultNode",
      "allNodeList"
    ]),
    computedNodeList() {
      return function (type) {
        return this.allNodeList.find(item => item.type == type);
      }
    },
    progressTime() {
      return function (row, item) {
        return item.progressList.find(dd => dd.progress == row.progress).date
      }
    },
    memberListComputed() {
      if(this.customerInfo) {
        if(this.customerInfo.memberList && this.customerInfo.memberList.length > 1) {
          return this.customerInfo.memberList.filter((item, index) => index < this.customerInfo.memberList.length - 1)
        } else {
          return this.customerInfo.memberList;
        }
      } else {
        return [];
      }
    },
    cooperateTitle() {
      if(this.companyCurrentType == '0') {
        if(this.customerInfo.companyType == '1') {
          return this.$t("customerInfo.FORWARDER")
        } else if(this.customerInfo.companyType == '2') {
          return this.$t("customerInfo.VENDOR")
        }
      } else if(this.companyCurrentType == '1') {
        if(this.customerInfo.companyType == '0') {
          return this.$t("customerInfo.FORWARDER")
        } else if(this.customerInfo.companyType == '2') {
          return this.$t("customerInfo.PURCHASER")
        }
      } else {
        if(this.customerInfo.companyType == '0') {
          return this.$t("customerInfo.VENDOR")
        } else if(this.customerInfo.companyType == '1') {
          return this.$t("customerInfo.PURCHASER")
        }
      }
    }
  },
  watch: {
    shopServerType() {
      this.updateList(true);
    },
    filteredFollow(newData, oldData) {
      this.getOrderList();
    },
    filterCurrency(newData, oldData) {
      this.form.currency = newData[0];
      this.getOrderList();
    },
    filterStatus(newData, oldData) {
      this.form.orderProgress = newData[0];
      this.getOrderList();
    },
    filterCompany(newData, oldData) {
      this.companyTypeList = newData[0];
      this.getOrderList();
    },
    newOrderUpdate() {
      this.getOrderList();
    }
  },
  mounted() {
    this.getCustomerDetail();
    this.onCustomerList();
  },
  methods: {
    updateList(clear = false) {
      this.$nextTick(() => {
        if(this.shopServerType == '0') {
          console.log(this.$refs.ServerPage);
          this.$refs.ServerPage.getList(clear)
        }
        if(this.shopServerType == '1') {
          this.$refs.CasePage.getList(clear)
        }
        if(this.shopServerType == '2') {
          this.$refs.FilePage.getList(clear)
        }
      });
    },
    openShopServerDrawer() {
      this.$refs.shopServerManage.open(this.customerInfo.companyId, this.customerInfo.goodsFlag);
    },
    openNoticeDrawer(id) {
      this.$refs.noticeDrawer.open('see', this.customerInfo.companyId, id);
    },
    getIsMyCustomer() {
      getIsMyCustomer(this.customerInfo.companyId).then((data) => {
        this.isMyCustomer = data;
        if(data) {
          this.getSendMessages(this.$route.query.companyId);
          this.getCustomerInfo();
        }
      })
    },
    visibleDropdownChange(val) {
      if(!val) {
        this.tableSelectedShow = null;
      }
    },
    uploadPersonSelect(item) {
      this.form.uploadPerson = item.nickName;
    },
    getListCompanyUserArraySelect(name, cb) {
      if(!name) {
        cb([]);
        return;
      }
      listCompanyUsers({
        name 
      }).then((data) => {
        cb(data);
      }).catch(() => {
        cb([]);
      })
    },
    goDashboard() {
      this.$router.push(`/dashboard/2?companyId=${this.customerInfo.companyId}`);
    },
    async getSendMessages(companyId) {
      try {
        getSendMessageList({
          companyId: companyId,
          currentPage: 1,
          pageSize: 4,
        }).then((data) => {
          this.sendMessages = data.list;
        })
      } catch (error) {
        console.error(error);
      }
    },
    async getCustomerInfo() {
      try {
        getCooperateCustomer({ 
          companyId: this.$route.query.companyId,
          currentPage: 1,
          pageSize: 1000
        }).then((data) => {
          this.companyConnectionArray = data.list;
        })
      } catch (error) {}
    },
    slideConnectionChange() {
      this.connectionSwiperPageActive = this.$refs.mySwiper.swiper.activeIndex;
    },
    swiperPageClick(index) {
      this.connectionSwiperPageActive = index;
      this.$refs.mySwiper.swiper.slideTo(index);
    },
    onCustomerList() {
      this.customerList.splice(0, this.customerList.length);
      var data_Customer = {
        currentPage: 1,
        pageSize: 100
      };
      getUserCustomList(data_Customer)
        .then((response) => {
          if (!response) return;
          this.customerList = response.list;
        })
    },
    count(c) {
      return formatNum(c.toFixed(2) || 0.0);
    },
    getCustomerDetail() {
      this.getOrderList();
      getCompanyEmployeeList(this.$route.query.companyId)
        .then((response) => {
          if (!response) return;
          this.customerInfo = response;
          this.getIsMyCustomer();
          this.shopServerParams.companyId = this.$route.query.companyId;
          this.$nextTick(() => {
            this.updateList(true);
          });
        })
        .catch((error) => {});
    },
    peopleProfileClose() {
      this.peopleProfileShow = false;
    },
    //新增订单更新前端渲染
    afterCreateOrder() {
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      //创建或更新之后再初始化一次桌面订单清单
      this.reload(['Order', 'Index', 'Customer', 'OrderDetails']);
      this.getCustomerDetail();
    },
    linkToOrder(businessOrderId) {
      this.$router.push({
        path: `/order/orderDetails?orderId=${businessOrderId.orderId}`,
      });
      window.localStorage.setItem('currentPath', '/order');
    },
    //更新订单
    editOrder(item) {
      this.action = 'edit';
      this.editContent = item;
      this.$refs.createOrderDialog.open();
    },
    //删除订单更新前端界面
    afterDeleteOrder(data) {
      //删除之后再初始化一次桌面订单清单
      this.reload(['Order', 'Index']);
      this.getCustomerDetail();
    },
    //删除订单操作
    deleteOrder(item) {
      this.deleteOrderShow = true;
      this.deleteInfo = item;
    },
    //收藏订单
    followOrder(item, index) {
      //   this.$refs.popover_order.showPopper = false;
      var data_follow = {
        orderId: item.orderId,
      };
      follow(data_follow)
        .then(() => {
          this.reload(['Index']);
          this.getCustomerDetail();
          item.followed = true;
        })
    },
    //解除收藏订单
    unFollowOrder(item, index) {
      //   this.$refs.popover_order.showPopper = false;
      this.$confirm('Confirm unfollow this order？', 'Unfollow', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
        .then(() => {
          var data_unfollow = {
            orderId: item.businessOrderId,
          };
          unfollow(data_unfollow)
            .then(() => {
              this.reload(['Index']);
              this.getCustomerDetail();
              item.followed = false;
            })
            .catch((error) => {
              this.$message.error('unfollow order error');
            });
        })
        .catch(() => {});
    },
    //创建新订单
    createOrder() {
      this.action = 'create';
      this.editContent = null;
      this.$refs.createOrderDialog.open();
    },
    searchGetOrderList() {
      this.form.currentPage = 1;
      this.getOrderList();
    },
    getOrderList() {
      let form = {
        ...this.form,
        companyId: this.$route.query.companyId,
        startTime: this.dateRange.length ? dayjs(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss") : '',
        endTime: this.dateRange.length ? dayjs(this.dateRange[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss") : '',
        favorite: this.filteredFollow[0]
      }
      this.loading_table = true;
      getOrderList(form).then((response) => {
        if (!response) return;
        this.orderList = [];
        this.form.totalElement = response.total;
        this.orderList = response.list.map(item => ({
          ...item,
          progress: Number(item.progress),
          icon: this.allNodeList.find(row => row.type == item.progress).icon,
          onProgress: Math.floor(1/(item.progressList.length)*(item.progressList.findIndex(row => row.progress == item.progress)+1)*100)
        }));
      }).catch((error) => {})
      .finally(() => (this.loading_table = false));
    },
    sortChange(data) {
      const { order, prop } = data;
      if(order) {
        this.form.sortField = prop;
        this.form.sortType = order == "ascending" ? "asc" : "desc";
      } else {
        this.form.sortType = "";
        this.form.sortField = "";
      }
      this.getOrderList();
    },
    clearFilter() {
      this.dateRange = [];
      this.form = {
        companyId: '',
        ci: '',
        pi: '',
        dateRange: [],
        sortType: '',
        sortField: '',
        currency: '',
        orderProgress: '',
        follow: '',
        currentPage: 1,
        pageSize: 10,
        totalElement: 0
      }
      this.getOrderList();
    },
    handleDownload() {
      var header = [
        this.companyCurrentType == '1' ? 
        this.$t('order.Purchaser') : 
        this.companyCurrentType == '0' ? 
        this.$t('home.VENDOR') : "",
        this.$t('order.Please select country'),
        "ci",
        "pi",
        this.$t('home.Currency'),
        this.$t('order.Amount'),
        "createTime",
        this.$t('order.Status'),
      ];
      var filterVal = [
        "customerAliasName",
        "country",
        "ci",
        "pi",
        "currency",
        "amount",
        "createTime",
        "progress",
      ];
      var filename = this.$t('home.Order');
      var data = this.formatJson(filterVal, this.orderList);
      this.$export_json_to_excel({
        header,
        data,
        filename,
      });
    },
    formatJson(filterVal, tableData) {
      return tableData.map((v) => {
        return filterVal.map((j) => {
          if(j == 'country') {
            return this.$isFindData(this.country, v[j], 'value', 'key')
          } else if(j == 'progress') {
            return this.allNodeList[v[j]].name
          } else {
            return v[j];
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  .customerBackground {
    width: 100%;
    min-width: 1280px;
    height: 415px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  .customerTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #122545;
    padding-left: 10px;
    .navBack {
      background: #ffffff;
      border-radius: 20px;
      padding: 5px 14px 5px 10px;
      font-size: 16px;
      font-weight: 600;
      color: #637381;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 2px;
      transition: all .3s;
      &:hover {
        color: #122545;
      }
      i {
        font-weight: bold;
      }
    }
    .right {
      cursor: pointer;
      color: #ffffff;
    }
  }
  .customerInfoMain {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    padding-left: 18px;
    gap: 12px;
    margin-bottom: 20px;
    &>div {
      flex: 1;
    }
    /deep/ .el-card {
      box-shadow: none !important;
    }
    .companyWrapper {
      
      padding-top: 13px;
      .companyLogo {
        width: 72px;
        height: 54px;
        border-radius: 4px;
        object-fit: cover;
      }
      .companyContainer {
        margin-top: 20px;
        width: 100%;
        .companyContainerItem {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          font-size: 14px;
          margin-bottom: 4px;
          line-height: 24px;
          .companyType {
            width: 56px;
            color: #637381;
            display: flex;
            align-items: center;
          }
          .companyName {
            flex: 1;
            color: #122545;
            margin-left: 12px;
            font-weight: 600;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;				//溢出内容隐藏
            text-overflow: ellipsis;		//文本溢出部分用省略号表示
            display: -webkit-box;			//特别显示模式
            -webkit-line-clamp: 2;			//行数
            line-clamp: 2;					
            -webkit-box-orient: vertical;	//盒子中内容竖直排列
          }
          .companyMoreWrapper {
            flex: 1;
            display: flex;
            align-items: center;
            margin-left: 6px;
            font-size: 16px;
            color: #48AC42;
            .moreImage {
              width: 40px;
              height: 40px;
              overflow: hidden;
              position: relative;
              cursor: pointer;
              margin: 4px;
              .el-image {
                width: 100%;
                height: 100%;
                border: 2px solid #F4F6F9;
                border-radius: 50%;
              }
              .mask {
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0,0,0,.5);
                width: 100%;
                height: 100%;
                font-weight: 600;
                color: #ffffff;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: 2px solid #f4f6f9;
              }
            }
            .member-avatar {
              position: relative;
              z-index: 0;
              margin-right: -15px;
              &:hover {
                z-index: 1;
              }
            }
            .companyMoreTo {
              display: flex;
              align-items: center;
              position: relative;
              cursor: pointer;
              margin-left: 40px;
            }
          }
        }
      }
    }
  }
}
.customerCalendar {
  position: relative;
  /deep/ .el-calendar__header {
    width: 100%;
    justify-content: center;
    border-bottom: none;
    padding: 0;
    margin: 0;
    .el-calendar__button-group {
      display: none;
    }
  }
  /deep/ .el-calendar__body {
    padding: 0;
    .el-calendar-table thead th {
      padding-block-start: 0;
      padding-block-end: 4px;
      font-size: 12px;
      width: 30px;
      height: 32px;
      text-align: center;
      font-weight: 500;
      color: #121212;
    }
    .el-calendar-table tbody td {
      border: none;
      border-radius: 4px;
      &.is-selected {
        background-color: #076F49 !important;
        color: #ffffff !important;
        box-shadow: none !important;
        .el-calendar-day {
          &:hover {
            background-color: #076F49 !important;
            color: #ffffff !important;
            box-shadow: none !important;
          }
        }
      }
    }
    .el-calendar-day {
      line-height: 20px;
      padding: 0;
      border-radius: 4px;
      margin-right: 0;
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 12px;
        height: 20px;
      }
    }
  }
}

.customerMessageCard {
  /deep/ .el-card__body {
    padding: 16px 0;
    height: 100%;
  }
  .shopServerBox {
    padding: 0 12px;
    height: calc(100% - 17px);
    overflow: overlay;
    /deep/ .list {
      margin-top: 14px;
      height: auto;
      .serverItemBox {
        padding: 12px 8px;
        height: 94px;
        margin-bottom: 8px;
        .header {
          display: none;
        }
        .content {
          margin-top: 0;
          zoom: .85;
          img {
            display: none;
          }
        }
      }
      .el-empty {
        margin: 0;
        img {
          width: 90px;
        }
      }
      .caseItemBox {
        padding: 4px;
        height: 125px;
        margin-bottom: 8px;
        .fileUrlBox {
          height: 85px;
          i {
            font-size: 30px;
          }
          .fileTitle {
            display: none;
          }
          .fileSize {
            display: none;
          }
        }
        .elImage {
          height: 85px;
          .image-slot {
            img {
              width: 40px;
            }
            p {
              font-size: 12px;
              margin-top: 0;
            }
          }
        }
        .title {
          margin: 6px 0;
          font-size: 12px;
        }
        .description {
          display: none;
        }
      }
      .bottom {
        display: none;
      }
    }
    /deep/ .pagination {
      display: none;
    }
  }
}
.customerMessageScroll {
  width: 100%;
  .customerMessageWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    .messageContainer {
      flex: 1;
      margin-left: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      h3 {
        width: 100%;
        font-size: 14px;
        color: #122545;
        font-weight: 500;
        line-height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      p {
        font-size: 12px;
        color: #757D8A;
        line-height: 17px;
        margin-top: 4px;
      }
    }
    &:hover {
      background: #F7F9FC;
    }
  }
}

.customerConnectionWrapper /deep/ {
  padding: 0 17px;
  .swiper-slide {
    border: 1px solid #E0E4EA;
    border-radius: 8px;
    height: 90px;
    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .el-image {
        width: 70px;
        height: 70px;
      }
    }
  }
}

.swiperPagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 25px;
  .pageItem {
    width: 40px;
    height: 4px;
    background: #CDE2DB;
    border-radius: 10px;
    cursor: pointer;
    &.active {
      background: #48AC42;
    }
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  line-height: 28px;
  h3 {
    font-size: 16px;
    font-weight: 500;
    color: rgba(46, 63, 79, 1);
  }
  i {
    // font-size: ;
  }
}
</style>
